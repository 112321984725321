<template>
	<div v-show="show"
		style="background-color: rgba(0,0,0,0.3);width: 100vw;height:100vh;z-index: 9999;position: fixed;top: 0;left: 0;">
		<el-dialog title="签到管理" :visible.sync="show" width="55%">
			<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
				<div style="width: 100%;height: 60vh;overflow-y:auto;padding: 20px;font-size: 16px;">
					<div style="margin-bottom: 10px;">已签到人数<span style="color:#277bf5">({{qiandaoList.length}}人)</span>
					</div>

					<div style="width:100%;">
						<div style="min-height: 49px;display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 10px 0;border-bottom: 1px solid #eee;"
							v-for="item in qiandaoList">
							<div style="color: #333;font-size:16px;font-weight: bold;">
								{{item.name?item.name:'签到人员'}}
							</div>
							<div style="font-size: 14px;">
								<span style="margin-right: 10px;">签到时间：</span><span
									style="color:#333;">{{item.qdtime}}</span>
							</div>
						</div>
					</div>
				</div>
				<div style="width: 100%;height: 60vh;overflow-y:auto;padding: 20px;font-size: 16px;">
					<div style="margin-bottom: 10px;">未签到人数<span style="color:#277bf5">({{wqiandaoList.length}}人)</span>
					</div>

					<div style="width:100%;">
						<div style="display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 10px 0;border-bottom: 1px solid #eee;"
							v-for="item in wqiandaoList">
							<div style="color: #333;font-size:16px;font-weight: bold;">
								{{item.name?item.name:'签到人员'}}
							</div>
							<div style="font-size: 14px;">
								<el-button type="success" size="mini" @click="showbm(item)">签到</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="show = false">取 消</el-button>
				<el-button type="primary" @click="show = false">确 定</el-button>
			</span>
		</el-dialog>
		
		<!--签到弹窗-->
		<el-dialog title="请确认签到" :visible.sync="showtcbm" width="30%">
			<div style="width: 100%;">
				<div style="margin-top: 10px;font-size: 16px;">
					签到人员：<span style="font-weight: bold;">{{wqdry.name}}</span>
				</div>
				<!-- <div style="display: flex;align-items: center;margin-top: 20px;">
					<div>报名时间：</div>
					<el-date-picker
					      v-model="time"
					      type="datetime"
					      placeholder="选择报名时间"
						  value-format="yyyy-MM-dd HH:mm:ss"
						  size="small">
					</el-date-picker>
				</div> -->
			</div>
		
			<span slot="footer" class="dialog-footer">
				<el-button @click="showtcbm = false">取 消</el-button>
				<el-button type="success" @click="surebm">签 到</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				id: '',
				qiandaoList: [],
				wqiandaoList:[],
				wqdry:'',
				showtcbm:false,
				time:''
			}
		},
		methods: {
			surebm(){
				this.$post({
					url: '/api/Wymeetingtz/glyhyqd',
					params: {
						id: this.wqdry.id
					}
				}).then((res) => {
					this.showtcbm = false
					this.$message.success('签到成功')
					this.getlist()
				})
			},
			showbm(item){
				this.wqdry = item
				this.showtcbm = true	
			},
			getlist() {
				this.$post({
					url: '/api/wymeeting/details',
					params: {
						id: this.id
					}
				}).then((res) => {
					this.qiandaoList = res.qdry
					this.wqiandaoList = res.qbry.filter(o1 => !this.qiandaoList.some(o2 => o1.user_id === o2.user_id));
					this.$parent.getinfo()
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

</style>